import { RefObject, useCallback, useEffect, useState } from 'react';

import {
  DEFAULT_INPUT_MIN_WIDTH,
  PASSWORD_ASTERISK_WIDTH,
  SPACING,
} from '../hookFormInput/constants';

interface IProps {
  value: string;
  spanAutoResizeRef: RefObject<HTMLSpanElement>;
  divContainerRef: RefObject<HTMLDivElement>;
  minWidth: number;
  isPasswordInput?: boolean;
  autoResize?: boolean;
  isPasswordVisible?: boolean;
}

const useAutoResize = ({
  value,
  spanAutoResizeRef,
  divContainerRef,
  minWidth,
  isPasswordInput,
  autoResize,
  isPasswordVisible,
}: IProps) => {
  const [autoResizeInputWidth, setAutoResizeInputWidth] = useState(0);

  const handleAutoResize = useCallback(() => {
    const spanAutoResizeWidth = spanAutoResizeRef.current?.offsetWidth;
    const autoResizeInputWidth =
      spanAutoResizeWidth && spanAutoResizeWidth > minWidth
        ? spanAutoResizeWidth
        : minWidth;
    setAutoResizeInputWidth(autoResizeInputWidth);
  }, [minWidth, spanAutoResizeRef]);

  const getInputStyle = () => {
    const style = {
      width: DEFAULT_INPUT_MIN_WIDTH,
      maxWidth: DEFAULT_INPUT_MIN_WIDTH,
    };

    if (divContainerRef.current?.offsetWidth)
      style.maxWidth = divContainerRef.current.offsetWidth - 20;

    if (autoResize && autoResizeInputWidth) {
      if (isPasswordInput && isPasswordVisible) {
        style.width = autoResizeInputWidth;
      } else if (isPasswordInput) {
        style.width = value.length * PASSWORD_ASTERISK_WIDTH + SPACING;
      } else {
        style.width = autoResizeInputWidth;
      }
    }

    return style;
  };

  useEffect(() => {
    if (autoResize) handleAutoResize();
  }, [value, autoResize, handleAutoResize]);

  return getInputStyle;
};

export default useAutoResize;
